<template>
  <div class="mainpage">
    <div class="head">
      <div class="head-title">实习详情</div>
      <div class="retutnbt" @click="toback">返回</div>
    </div>
    <div class="line"></div>
    <div class="maincontain">
      <div class="t-contain">
        <div class="h-title">{{ info.practice_plan_name }}</div>
      </div>

      <div class="f-contain">
        <div class="f-title">基本信息</div>
        <div class="contain">
          <div class="item_c">实践课程： {{ info.course_name }}</div>
          <div class="item_c">实践类型： {{ info.config_type_name }}</div>
          <div class="item_c">
            实习时间： {{ info.start_date | dataformat }}~{{
            info.end_date | dataformat
            }}
          </div>
          <div class="item_c">实习形式：{{ info.type | typeFilter }}</div>
          <div class="item_c">负责老师： {{ info.teacher_names }}</div>
        </div>
      </div>

      <div class="g-contain">
        <div class="f-title">实习介绍</div>
        <div class="contain">
          <div class="item_c">{{ info.remark }}</div>
        </div>
      </div>
      <div class="h-contain">
        <div class="f-title">实习任务</div>
        <div class="contain">
          <div class="item_c" v-if="student_sign_in_flag">
            <div class="text_t">学生签到</div>
            <div class="text_t2">
              签到规则：每天签到{{
              settingInfo ? settingInfo.student_sign_in_count : ""
              }}次
            </div>
          </div>
          <div class="item_c" v-if="settingInfo && settingInfo.teacher_task == '1'">
            <div class="text_t">教师签到</div>
            <div class="text_t2">
              签到规则：每天签到{{
              settingInfo ? settingInfo.teacher_sign_in_count : ""
              }}次
            </div>
          </div>
          <div class="item_c" v-if="daily_report_flag">
            <div class="text_t">日报</div>
            <div class="text_t2">
              每{{ daily_report_list[0] }}天至少提交{{
              daily_report_list[1]
              }}篇，提交总篇数不少于{{ daily_report_list[2] }}篇
            </div>
          </div>
          <div class="item_c" v-if="weekly_report_flag">
            <div class="text_t">周报</div>
            <div class="text_t2">
              每{{ weekly_report_list[0] }}周至少提交{{
              weekly_report_list[1]
              }}篇，提交总篇数不少于{{ weekly_report_list[2] }}篇
            </div>
          </div>
          <div class="item_c" v-if="monthly_report_flag">
            <div class="text_t">月报</div>
            <div class="text_t2">
              每{{ monthly_report_list[0] }}月至少提交{{
              monthly_report_list[1]
              }}篇，提交总篇数不少于{{ monthly_report_list[2] }}篇
            </div>
          </div>
          <div class="item_c">
            <div class="text_t">实习报告</div>
            <div class="text_t2">
              提交时间：{{
              settingInfo.report_commit_start_date | dataformat
              }}~{{ settingInfo.report_commit_end_date | dataformat }}
            </div>
          </div>
        </div>
      </div>
      <div class="j-contain">
        <div class="f-title">实习报告</div>
        <div class="contain">
          <div class="t_text">{{ settingInfo.url_name }}</div>
          <div class="image_con" @click="download(settingInfo.url, settingInfo.url_name)" v-if="settingInfo.url_name !=''">
            <el-image class="elimage" :src="icon5" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { getpracticeinfo } from "@/api/practiceresult";

const typeMap = {
  0: "全部",
  1: "自主报名",
  2: "集中安排",
};
export default {
  name: "mainpage",
  components: {},
  data() {
    return {
      settingInfo: "",
      info: "",
      daily_report_list: [],
      weekly_report_list: [],
      monthly_report_list: [],
      student_sign_in_flag: true,
      daily_report_flag: true,
      weekly_report_flag: true,
      monthly_report_flag: true,
      teacher_task_flag: true,
      icon4: require("@/assets/查看.png"),
      icon5: require("@/assets/下载.png"),
    };
  },
  filters: {
    dataformat(value) {
      if (!value) return "";
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss");
    },
    typeFilter(val) {
      return typeMap[val];
    },
  },
  computed: {
    practice_plan_id() {
      return this.$route.query.practice_plan_id;
    },
    student_id() {
      return this.$route.query.student_id;
    },
  },
  created() {
    //获取实习计划详情
    this.getpracticeinfo({
      practice_plan_id: this.practice_plan_id,
      student_id: this.student_id,
    });
  },
  methods: {
    toback() {
      this.$router.push({
        path: "/home/personalcenter/mainpage",
        query: {
          id: 6,
          componentId: "Myinternship",
        },
      });
    },
    //获取实习计划详情
    getpracticeinfo(params) {
      getpracticeinfo(params)
        .then((response) => {
          if (response.code == 0) {
            this.info = response.data;
            this.settingInfo = response.data.settingInfo;
            //判断老师和学生的实习任务
            const student_task = response.data.settingInfo.student_task;
            this.student_sign_in_flag = student_task.indexOf("1") != -1;
            this.daily_report_flag = student_task.indexOf("2") != -1;
            this.weekly_report_flag = student_task.indexOf("3") != -1;
            this.monthly_report_flag = student_task.indexOf("4") != -1;
            this.daily_report_list =
              response.data.settingInfo.daily_report.split(",");
            this.weekly_report_list =
              response.data.settingInfo.daily_report.split(",");
            this.monthly_report_list =
              response.data.settingInfo.daily_report.split(",");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    download(url, name) {
      var ele = document.createElement("a"); // 创建下载链接
      ele.download = name; //设置下载的名称
      ele.style.display = "none"; // 隐藏的可下载链接
      ele.href = url;
      // 绑定点击时间
      document.body.appendChild(ele);
      ele.click();
      // 然后移除
      document.body.removeChild(ele);
    },
  },
};
</script>
<style scoped lang="scss">
.mainpage {
  min-height: calc(100vh - 240px);
  .head {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding-left: 20px;
    padding-top: 10px;
    background: #ffffff;
    .head-title {
      font-size: 20px;
      font-weight: 400;
      color: #3d84ff;
      user-select: none;
      white-space: nowrap;
    }
    .retutnbt {
      margin-right: 40px;
      width: 64px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #3d84ff;
      border-radius: 4px;
      color: white;
      user-select: none;
      cursor: pointer;
       font-size: 14px;
    }
  }
  .line {
    border-bottom: 2px solid #cccccc;
  }

  .maincontain {
    border-radius: 4px;
    padding-bottom: 20px;
    .t-contain {
      min-height: 70px;
      background: #ffffff;
      box-sizing: border-box;
      padding: 20px;
      margin-bottom: 10px;
      .h-title {
        font-size: 25px;
        font-weight: 400;
        color: #222222;
      }
    }
    .tip_con {
      margin-bottom: 10px;
      height: 32px;
      background-color: rgba(230, 247, 255, 1);

      border: 1px solid rgba(145, 213, 255, 1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;
      .icon_con {
        display: flex;
        justify-content: center;
        align-items: center;
        .icon_text {
          background: #0773fc;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          color: white;
          text-align: center;
        }
        .icon_name {
          margin-left: 6px;
          font-size: 16px;
          font-weight: 400;
          color: #666;
        }
      }
      .look_bt {
        font-size: 16px;
        font-weight: 400;
        color: #666;
        user-select: none;
        cursor: pointer;
      }
    }

    .f-contain {
      margin-top: 20px;

      background: #ffffff;
      box-sizing: border-box;
      padding: 20px;

      .f-title {
        font-size: 25px;
        font-weight: 400;
        color: #222222;
      }
      .contain {
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        .item_c {
          margin-top: 10px;
          flex-basis: 33%;
          font-size: 18px;
          font-weight: 400;
          color: #666666;
        }
      }
    }
    .g-contain {
      margin-top: 20px;

      background: #ffffff;
      box-sizing: border-box;
      padding: 20px;

      .f-title {
        font-size: 25px;
        font-weight: 400;
        color: #222222;
      }
      .contain {
        margin-top: 10px;
        .item_t {
          margin-top: 10px;
          flex-basis: 33%;
          font-size: 18px;
          font-weight: 400;
          color: #666666;
        }
        .item_c {
          margin-top: 10px;
          flex-basis: 33%;
          font-size: 16px;
          font-weight: 400;
          color: #666666;
        }
      }
    }

    .h-contain {
      margin-top: 20px;

      background: #ffffff;
      box-sizing: border-box;
      padding: 20px;

      .f-title {
        font-size: 25px;
        font-weight: 400;
        color: #222222;
      }
      .contain {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .item_c {
          margin-top: 10px;
          flex-basis: 50%;
          .text_t {
            font-size: 18px;
            font-weight: 400;
            color: #666666;
          }
          .text_t2 {
            margin-top: 6px;
            font-size: 14px;
            font-weight: 400;
            color: #666666;
          }
        }
      }
    }

    .j-contain {
      margin-top: 20px;

      background: #ffffff;
      box-sizing: border-box;
      padding: 20px;

      .f-title {
        font-size: 25px;
        font-weight: 400;
        color: #222222;
      }
      .contain {
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .t_text {
          font-size: 16px;
          font-weight: 400;
          color: #0773fc;
          cursor: pointer;
        }
        .image_con {
          margin-left: 10px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .elimage {
            margin-left: 4px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
